<template>
  <v-menu
    ref="menu"
    v-model="show"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        outlined
        dense
        clearable
        background-color="white"
        v-bind="attrs"
        v-on="on"
        @click:clear="onClearClick"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      scrollable
    >
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="show = false"
      >
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="onOKClick(date)"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "TimePicker",
  data() {
    return {
      show: false,
      date: null
    }
  },
  props: {
    label: {
      type: String,
      required: true
    },
  },
  methods: {
    onOKClick(d) {
      console.log(d)
      this.$refs.menu.save(d)
      this.$emit("on-ok-click", d)
    },
    onClearClick() {
      this.date = null
      this.$emit("on-clear-click")
    }
  }
}
</script>
