<template>
  <div class="text-center">
    <v-dialog
      v-model="showLogOutConfirm"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title class="lighten-2">
          <div v-if="!title">
            ログアウト
          </div>
          <div v-else>
            {{ this.title }}
          </div>
        </v-card-title>

        <v-card-text>
          ログアウトします。よろしいですか？
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="$emit('close-logout-confirm')"
          >
            キャンセル
          </v-btn>
          <v-btn
            color="red"
            text
            @click="onLogoutClick"
          >
            実行
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  <v-overlay :value="onProgress">
    <v-progress-circular indeterminate color="primary" ></v-progress-circular>
  </v-overlay>

  </div>  
</template>

<script>
import store from "@/store"


export default {
  name: "LogOutConfirm",
  data() {
    return {
      onProgress: false,
    }
  },
  props: {
    showLogOutConfirm: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: false
    }
  },
  methods: {
    onLogoutClick() {
      this.onProgress = true
      store.commit("onSignedOutFromLine")
      this.onProgress = false
      this.$router.push("/signin")
    }
  }
}
</script>
