<template>
  <div class="text-center">
    <v-dialog
      v-model="showDataDetail"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="lighten-2">
          レコード詳細
        </v-card-title>

        <v-card-text>
          <v-container v-if="record">
            <v-row>
              <v-text-field v-model="record.type" outlined dense readonly label="タイプ" class="mx-2" />
              <v-text-field v-model="record.label" outlined dense readonly label="ラベル" class="mx-2" />
              <v-text-field v-model="record.result" outlined dense readonly label="結果 (g/m2)" class="mx-2" />
              <v-text-field v-model="record.confidence" outlined dense readonly label="信頼度" class="mx-2" />
              <v-text-field v-model="record.sampled_at" outlined dense readonly label="取得時刻 (UTC)" class="mx-2" />
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="$emit('close-data-detail')"
          >
            閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  <v-overlay :value="onProgress">
    <v-progress-circular indeterminate color="primary" ></v-progress-circular>
  </v-overlay>

  </div>  
</template>

<script>
import store from "@/store"


export default {
  name: "DataDetail",
  data() {
    return {
      onProgress: false,
    }
  },
  props: {
    showDataDetail: {
      type: Boolean,
      required: true
    },
    record: {
      type: Object,
      required: false
    }
  },
  methods: {
    onLogoutClick() {
      this.onProgress = true
      store.commit("onSignedOutFromLine")
      this.onProgress = false
      this.$router.push("/signin")
    }
  }
}
</script>
